var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-popover",
        { attrs: { placement: "right", width: "400", trigger: "click" } },
        [
          _c(
            "div",
            { staticClass: "thAttr" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    model: _vm.modelInfo,
                    size: "small",
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "字段样式" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "字段样式" },
                                  model: {
                                    value: _vm.tipsObject.textStyle,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tipsObject, "textStyle", $$v)
                                    },
                                    expression: "tipsObject.textStyle",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "字段", value: "1" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "输入框", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "必输项" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "必输项" },
                                  model: {
                                    value: _vm.tipsObject.isbt,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tipsObject, "isbt", $$v)
                                    },
                                    expression: "tipsObject.isbt",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "Y", value: "Y" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "N", value: "N" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否有字段说明" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "是否有字段说明" },
                                  model: {
                                    value: _vm.tipsObject.isTips,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tipsObject, "isTips", $$v)
                                    },
                                    expression: "tipsObject.isTips",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "Y", value: "Y" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "N", value: "N" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "字段说明" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "字段说明" },
                                model: {
                                  value: _vm.tipsObject.tips,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tipsObject, "tips", $$v)
                                  },
                                  expression: "tipsObject.tips",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "填写示例" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "填写示例" },
                                model: {
                                  value: _vm.tipsObject.sl,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tipsObject, "sl", $$v)
                                  },
                                  expression: "tipsObject.sl",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否来源中台" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "是否来源中台" },
                                  model: {
                                    value: _vm.tipsObject.iszt,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tipsObject, "iszt", $$v)
                                    },
                                    expression: "tipsObject.iszt",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "Y", value: "Y" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "N", value: "N" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "字段主键" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "字段主键" },
                                model: {
                                  value: _vm.tipsObject.key,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tipsObject, "key", $$v)
                                  },
                                  expression: "tipsObject.key",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("i", {
            staticClass: "el-icon-s-operation",
            attrs: { slot: "reference" },
            slot: "reference",
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }