var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: { model: _vm.modelInfo, size: "small", "label-width": "50px" },
        },
        [
          _c(
            "el-row",
            { staticClass: "demo-row-inline", attrs: { gutter: 22 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "列" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100px" },
                        attrs: { placeholder: "列", min: 0 },
                        on: { change: _vm.getRowArr },
                        model: {
                          value: _vm.row,
                          callback: function ($$v) {
                            _vm.row = $$v
                          },
                          expression: "row",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "行" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100px" },
                        attrs: { placeholder: "行", min: 0, controls: "false" },
                        on: { change: _vm.getLineArr },
                        model: {
                          value: _vm.line,
                          callback: function ($$v) {
                            _vm.line = $$v
                          },
                          expression: "line",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 4 } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "singleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            "row-key": "id",
            "tree-props": { children: "children", hasChildren: "hasChildren" },
            "header-row-style": "backgroundColor: #f5f7fa",
            "header-cell-style": "backgroundColor: #f5f7fa",
          },
          on: { "current-change": _vm.handleCurrentChange },
        },
        [
          _vm._l(_vm.headerData, function (item, index) {
            return _c(
              "el-table-column",
              {
                key: index,
                attrs: {
                  prop:
                    item.children && item.children.length > 0 ? "" : item.key,
                  width: "200",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (headers) {
                        return [
                          _c("el-input", {
                            staticStyle: { width: "100px" },
                            attrs: { size: "mini", placeholder: "请输入" },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          }),
                          _c("Tips", {
                            staticStyle: { display: "inline-block" },
                            attrs: { tipsObject: item },
                          }),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.addHeaderChild(item)
                                },
                              },
                            },
                            [_vm._v("增加子表头")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            staticStyle: { width: "100px" },
                            attrs: { size: "mini", placeholder: "请输入" },
                            model: {
                              value: scope.row[item.key].name,
                              callback: function ($$v) {
                                _vm.$set(scope.row[item.key], "name", $$v)
                              },
                              expression: "scope.row[item.key].name",
                            },
                          }),
                          _c("Tips", {
                            staticStyle: { display: "inline-block" },
                            attrs: { tipsObject: scope.row[item.key] },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              _vm._l(item.children, function (ele, index) {
                return item.children.length > 0
                  ? _c("el-table-column", {
                      key: index,
                      attrs: { prop: "ele.key", width: "200" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function (headers) {
                              return [
                                _c("el-input", {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: ele.name,
                                    callback: function ($$v) {
                                      _vm.$set(ele, "name", $$v)
                                    },
                                    expression: "ele.name",
                                  },
                                }),
                                _c("Tips", {
                                  staticStyle: { display: "inline-block" },
                                  attrs: { tipsObject: ele },
                                }),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: scope.row[ele.key].name,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row[ele.key], "name", $$v)
                                    },
                                    expression: "scope.row[ele.key].name",
                                  },
                                }),
                                _c("Tips", {
                                  staticStyle: { display: "inline-block" },
                                  attrs: { tipsObject: scope.row[ele.key] },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm._e()
              }),
              1
            )
          }),
          _vm.headerData.length > 0 && _vm.tableData.length > 0
            ? _c("el-table-column", {
                attrs: { label: "操作", width: "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.addRow(scope)
                                },
                              },
                            },
                            [_vm._v("增加子行")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteRow(scope)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3836953930
                ),
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }