<template>
  <div class="main" v-loading="loading">
    <div class="search-top" style="padding-top: 20px">
      <el-form
        ref="ruleForm"
        :rules="rules"
        :inline="true"
        :model="modelInfo"
        class="topForm"
        size="mini"
        label-width="110px"
      >
        <el-row :gutter="2">
          <el-col :span="6">
            <el-form-item label="模板编号" prop="paModelInfoCode">
              <el-input
                style="width: 210px"
                v-model="modelInfo.paModelInfoCode"
                placeholder="模板编号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="模板名称" prop="paModelInfoName">
              <el-input
                style="width: 210px"
                v-model="modelInfo.paModelInfoName"
                placeholder="模板名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="模板分类" prop="paModelType">
              <el-select
                v-model="modelInfo.paModelType"
                placeholder="模板分类"
                style="width: 210px"
              >
                <el-option label="模板1" value="1"></el-option>
                <el-option label="模板2" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="模板类型" prop="paMoSelete">
              <el-select
                @change="selectTeamOption"
                v-model="modelInfo.paMoSelete"
                placeholder="模板类型"
                style="width: 210px"
              >
                <el-option
                  v-for="item in templateOpt"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="2">
          <el-col :span="6">
            <el-form-item label="模板适用单位" prop="unitList">
              <avue-input-tree
              multiple
              checkStrictly
              default-expand-all
              v-model="modelInfo.unitList"
              placeholder="模板适用单位"
              :dic="treeData"
              :props="{label: 'deptName', value: 'id'}"
            ></avue-input-tree>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="模板有效期" prop="dateTime">
              <el-date-picker
                style="width: 211px"
                v-model="modelInfo.dateTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="业务编码" prop="busiCode">
              <el-input
                style="width: 210px"
                v-model="modelInfo.busiCode"
                placeholder="业务编码"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-row>
        <el-radio-group
          v-model="radio"
          style="width: 100%"
          @change="radioChange()"
        >
          <el-col :span="12" v-for="(i, index) in tagData" :key="index">
            <div class="tag-list">
              <el-radio
                :label="index"
                style="
                  margin-right: 8px;
                  max-width: 120px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
                >{{ i.labelName }}</el-radio
              >
              <div style="font-size: 16px; width: 38px; display: flex">
                <el-popover placement="bottom" width="200" trigger="click">
                  <el-input
                    clearable
                    size="mini"
                    style="width: 200px"
                    v-model="i.labelName"
                  ></el-input>
                  <i
                    class="el-icon-edit"
                    slot="reference"
                    style="margin-right: 5px; cursor: pointer"
                  ></i>
                </el-popover>

                <i
                  style="cursor: pointer"
                  class="el-icon-delete"
                  @click="removeTag(i)"
                ></i>
              </div>

              <el-switch
                @change="changeShowState(i)"
                v-model="i.showState"
                active-color="#13ce66"
                inactive-color="#ff4949"
                style="margin: 0 6px"
                active-value="Y"
                inactive-value="N"
              >
              </el-switch>
              <div style="font-size: 14px" class="flexSelect">
                <span style="margin-right: 4px">布局</span>
                <el-select
                  size="mini"
                  v-model="i.columnNum"
                  placeholder=""
                  style="width: 70px"
                >
                  <el-option label="1列" :value="24"></el-option>
                  <el-option label="2列" :value="12"></el-option>
                  <el-option label="3列" :value="8"></el-option>
                  <el-option label="4列" :value="6"></el-option>
                </el-select>
              </div>
              <div style="font-size: 14px">
                <span style="margin: 0 4px">是否表格</span>
                <el-select
                  size="mini"
                  v-model="i.isTable"
                  placeholder=""
                  style="width: 60px"
                  @change="changeTable(i.isTable)"
                >
                  <el-option label="是" value="Y"></el-option>
                  <el-option label="否" value="N"></el-option>
                </el-select>
              </div>
              <div style="font-size: 14px" v-if="i.isTable === 'Y'">
                <span style="margin: 0 4px">表格个数</span>
                <el-select
                  size="mini"
                  v-model="i.tableNum"
                  placeholder=""
                  style="width: 70px"
                  @change="changeTableNum(i.tableNum)"
                >
                  <el-option label="1" :value="1"></el-option>
                  <el-option label="2" :value="2"></el-option>
                  <el-option label="3" :value="3"></el-option>
                  <el-option label="4" :value="4"></el-option>
                </el-select>
              </div>
              <div style="font-size: 14px">
                <span style="margin: 0 4px">是否是说明</span>
                <el-select
                  size="mini"
                  v-model="i.isUeditor"
                  placeholder=""
                  style="width: 60px"
                  @change="changeTable(i.isUeditor, 3)"
                >
                  <el-option label="是" value="Y"></el-option>
                  <el-option label="否" value="N"></el-option>
                </el-select>
              </div>
            </div>
          </el-col>
        </el-radio-group>
      </el-row>
      <div class="save-btn">
        <el-button size="mini" @click="addTag()">
          <i class="el-icon-plus"></i>添加标签</el-button
        >
        <el-button type="primary" size="mini" @click="saveData()"
          >保存当前标签数据</el-button
        >
      </div>
    </div>
    <div class="table-list" v-if="isTable === 'N'">
      <el-table
        size="mini"
        border
        :data="columns"
        style="width: 100%"
        :header-cell-style="{ background: '#fafafa', color: '#000' }"
      >
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="date" label="显示名称" width="140">
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.isIndex === 'N'"
              :disabled="isDisabled"
              clearable
              size="mini"
              v-model="scope.row.columnName"
            ></el-input>
            <avue-form
              class="treeForm"
              :option="option"
              v-if="scope.row.isIndex === 'Y'"
              v-model="scope.row.treeName"
            ></avue-form>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="名称引用" width="140">
          <template slot-scope="scope">
            <el-select
              :disabled="isDisabled"
              size="mini"
              v-model="scope.row.isIndex"
            >
              <el-option label="  " value="N"></el-option>
              <el-option label="指标" value="Y"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="字段类型" width="140">
          <template slot-scope="scope">
            <el-select
              :disabled="isDisabled"
              size="mini"
              v-model="scope.row.fieldType"
              placeholder=""
            >
              <el-option
                v-for="item in fieldTypeOpt"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="name" label="字段样式" width="140">
            <template slot-scope="scope">
              <el-select
                :disabled="isDisabled"
                size="mini"
                v-model="scope.row.fieldStyle"
              >
                <el-option label="字段" value="1"></el-option>
                <el-option label="输入框" value="2"></el-option>
                <el-option label="字段+输入框" value="3"></el-option>
              </el-select>
            </template>
          </el-table-column> -->
        <el-table-column prop="address" label="默认值" width="100">
          <template slot-scope="scope">
            <el-input
              :disabled="isDisabled"
              clearable
              size="mini"
              v-model="scope.row.dataDefault"
            ></el-input>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="address" label="引用参照" width="100">
            <template slot-scope="scope">
              <el-select
                :disabled="isDisabled"
                size="mini"
                v-model="scope.row.canzhao"
                placeholder=""
              >
              </el-select>
            </template>
          </el-table-column> -->
        <el-table-column prop="address" label="枚举值" width="120">
          <template slot-scope="scope">
            <el-select
              clearable
              size="mini"
              v-model="scope.row.enumValue"
              placeholder=""
            >
              <el-option
                v-for="item in enumOpt"
                :key="item.id"
                :label="item.dictValue"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="单位" width="100">
          <template slot-scope="scope">
            <el-select
              :disabled="isDisabled"
              size="mini"
              v-model="scope.row.unitType"
            >
              <el-option
                v-for="item in unitTypeOpt"
                :key="item.dictKey"
                :label="item.dictValue"
                :value="item.dictKey"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="必填项" width="100">
          <template slot-scope="scope">
            <el-select
              :disabled="isDisabled"
              size="mini"
              v-model="scope.row.columnNullable"
            >
              <el-option label="是" value="Y"></el-option>
              <el-option label="否" value="N"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="columnPosition" label="显示顺序" width="100">
          <template slot-scope="scope">
            <el-input
              :disabled="isDisabled"
              clearable
              size="mini"
              v-model="scope.row.columnPosition"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="单据是否编辑" width="120">
          <template slot-scope="scope">
            <el-select
              :disabled="isDisabled"
              size="mini"
              v-model="scope.row.editState"
            >
              <el-option label="是" value="Y"></el-option>
              <el-option label="否" value="N"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="address" label="单据是否显示" width="120">
            <template slot-scope="scope">
              <el-select
                :disabled="isDisabled"
                size="mini"
                v-model="scope.row.singleRowStatus"
              >
                <el-option label="是" value="Y"></el-option>
                <el-option label="否" value="N"></el-option>
              </el-select>
            </template>
          </el-table-column> -->
        <el-table-column prop="address" label="是否独占一行" width="120">
          <template slot-scope="scope">
            <el-select
              :disabled="isDisabled"
              size="mini"
              v-model="scope.row.singleRowStatus"
            >
              <el-option label="是" value="Y"></el-option>
              <el-option label="否" value="N"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="dataLength" label="输入长度" width="100">
          <template slot-scope="scope">
            <el-input
              :disabled="isDisabled"
              clearable
              size="mini"
              v-model="scope.row.dataLength"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="columnFeild" label="字段主键" width="100">
        </el-table-column>
        <el-table-column prop="address" label="是否有字段说明" width="120">
          <template slot-scope="scope">
            <el-select
              :disabled="isDisabled"
              size="mini"
              v-model="scope.row.commentState"
            >
              <el-option label="是" value="Y"></el-option>
              <el-option label="否" value="N"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="columnComment" label="字段说明" width="100">
          <template slot-scope="scope">
            <el-popover placement="top" width="400" trigger="click">
              <el-input
                maxlength="300"
                :rows="6"
                show-word-limit
                type="textarea"
                v-model="scope.row.columnComment"
              ></el-input>
              <el-button slot="reference" size="mini">填写</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="commentExample" label="填写示例" width="100">
          <template slot-scope="scope">
            <el-popover placement="top" width="400" trigger="click">
              <el-input
                maxlength="300"
                :rows="6"
                show-word-limit
                type="textarea"
                v-model="scope.row.commentExample"
              ></el-input>
              <el-button slot="reference" size="mini">填写</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="是否来源中台" width="120">
          <template slot-scope="scope">
            <el-select
              :disabled="isDisabled"
              size="mini"
              v-model="scope.row.isDatacenter"
            >
              <el-option label="是" value="Y"></el-option>
              <el-option label="否" value="N"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small">编辑</el-button>
            <el-button @click="delDataList(scope.row)" type="text" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div
        style="text-align: center; border: 1px solid #efefef; margin: 10px 0"
      >
        <el-button
          size="mini"
          @click="addTableList()"
          type="text"
          :disabled="this.tagData.length <= 0"
        >
          <i class="el-icon-plus"></i> 添加一行数据</el-button
        >
      </div>
    </div>

    <!--  -->

    <div class="table-r" v-if="isTable === 'Y'">
      <MaKingTable
        class="makingClass"
        :ref="'childRef' + index"
        :pData="item"
        v-for="(item, index) in tableNumData"
        :key="index"
      />
    </div>
    <div v-if="isUeditor === 'Y'">
      <avue-ueditor v-model="ueditorText" v-bind="options"></avue-ueditor>
    </div>
  </div>
</template>
  
  <script>
  import { getDictionaryTreeCode, getParentList } from "@/api/system/dictbiz";
  import {saveTemplateData, listTemplateData,listTemplateTableData,updateTemplatePageLabel,saveTemplateTableData,deptListTwoLevelTree} from "@/api/formTemplate/index.js";
  import MaKingTable from "./maKingTable.vue";
  import { deptChildTree } from "@/api/reportTemplate";
  export default {
    name: "template-making",
    components: { MaKingTable },
    data() {
      return {
        options: {
          config: {
            menus: ["head"],
          },
        },
        ueditorText: "",
        treeName: {},
        option: {
          submitBtn: false,
          emptyBtn: false,
          size: "mini",
          labelWidth: 0,
          menuBtn: false,
          column: [
            {
              span: 24,
              label: "",
              width: 240,
              prop: "indexId",
              hide: true,
              type: "tree",
              emitPath: false,
              dicData: [],
              // dicUrl: "/api/snows-eop/indexLedger/listIndexLedgerSystemTree",
              props: {
                label: "name",
                value: "id",
                children: "childrenList",
              },
              multiple: false,
              nodeClick: (data, node, nodeComp) => {
                console.log(data);
              },
              parent: false,
            },
          ],
        },
        content: "",
        disabled: false,
        editorConfig: {
          // 配置项
        },
        form: {
          label: "",
          width: "",
        },
        isTable: "N",
        isUeditor: "N",
        tableData: [],
        dialogFormVisible: false,
        modelId: "", //模板id
        labelId: "", //标签id
        pageParent: {
          pageSize: 999,
          pageSizes: [10, 30, 50, 100, 200],
          currentPage: 1,
          total: 0,
        },
        loading: false,
        dateTime: [],
        treeData: [],
        rules: {
          paModelInfoCode: [
            { required: true, message: "请输入模板编号", trigger: "blur" },
          ],
          paModelInfoName: [
            { required: true, message: "请输入模板名称", trigger: "blur" },
          ],
          busiCode: [
            { required: true, message: "请输入业务编码", trigger: "blur" },
          ],
          paModelType: [
            { required: true, message: "请选择模板分类", trigger: "change" },
          ],
          paMoSelete: [
            { required: true, message: "请选择模板类型", trigger: "change" },
          ],
          unitList: [
            { required: true, message: "请选择模板适用单位", trigger: "change" },
          ],
          dateTime: [
            { required: true, message: "请选择日期", trigger: "change" },
          ],
        },
        isDisabled: false,
        radio: 0,
        value: true,
        jqflArr: [],
        pageLabel: {},
        visible: false,
        tableColumnL: "",
        tableRow: "",
        modelInfo: {
          paModelInfoId: "", //模板ID
          paModelInfoCode: "", //模版编号
          paModelInfoName: "", //模版名称
          startTime: "", //
          endTime: "", //
          busiCode: "", // 业务编码
          paModelType: "", ///模版分类1-统计局，2-集团内
          paModelTypeCode: "", ///模版类型
          unitList: [], //适用单位
          useUnitText: "", //适用单位回填
          dateTime: [],
          paMoSelete: "", //模版类型
        },
        templateOpt: [],
        unitTypeOpt: [
          {
            label: "单位",
            value: "1",
          },
        ],
        enumOpt: [],
        fieldTypeOpt: [
          {
            label: "文本框",
            value: 1,
          },
          {
            label: "下拉框",
            value: 2,
          },
          {
            label: "大文本",
            value: 3,
          },
          {
            label: "联系方式",
            value: 4,
          },
          {
            label: "证件",
            value: 5,
          },
          {
            label: "单选",
            value: 6,
          },
          {
            label: "多选",
            value: 7,
          },
          {
            label: "日期",
            value: 8,
          },
          {
            label: "日期时间",
            value: 9,
          },
          {
            label: "数值",
            value: 10,
          },
          {
            label: "整数",
            value: 11,
          },
        ],
        tagData: [],
        columns: [],
        tableColumn: [],
        tableNum: 1,
        tableNumData: [{}],
      };
    },
    created() {
      this.initData();
      if (this.$route.query.moduleId) {
        this.loading = true;
        let params = {
          modelId: this.$route.query.moduleId, //模块id
        };
        listTemplateData(params).then((res) => {
          this.modelInfo = res.data.data.paModelInfo;
          this.selectPaMode(this.modelInfo.paModelTypeCode);
          this.tagData = res.data.data.templatePageLabelList;
          this.isTable = this.tagData[0].isTable;
          if (this.tagData.length > 0) {
            if (this.tagData[0].isTable == "Y") {
              this.isTable = this.tagData[0].isTable;
              let oldData = JSON.parse(this.tagData[0].oldData);
              // this.tableNumData = oldData.tableData;
              this.tableNumData = { ...oldData };
              this.tagData[0].tableNum = oldData.length;
              this.tableNum = oldData.length;
            } else {
              this.columns = res.data.data.templateTableColumnList;
              this.columns.forEach((item) => {
                if (item.isIndex === "Y") {
                  item.treeName = { indexId: item.indexId };
                }
              });
            }
          }
          this.modelInfo.updTime = "";
          this.modelInfo.crtTime = "";
          this.modelInfo.dateTime = [
            res.data.data.paModelInfo.startTime,
            res.data.data.paModelInfo.endTime,
          ];
          this.loading = false;
        });
      }
    },
    mounted() {},
    computed: {},
    watch: {},
    methods: {
      selectTeamOption(index) {
        this.templateOpt.forEach(item => {
          if(item.dictKey == index){
            this.modelInfo.paModelTypeCode = item.dictKey;
            this.modelInfo.paModelTypeName = item.dictValue;
          }
        })
      },
      selectPaMode(code) {
        this.modelInfo.paMoSelete = code; //123
      },
      tableRowChange() {},
      changeTable(type, index) {
        if (index === 3) {
          if (type == "Y") {
            this.isUeditor = type;
            this.isTable = "M";
          } else {
            this.isUeditor = type;
            this.isTable = "N";
          }
        } else {
          this.isTable = type;
          this.isUeditor = "N";
          if (type == "Y") {
            this.tagData[this.radio].columnNum = 24;
          } else {
            this.tagData[this.radio].columnNum = 8;
          }
        }
      },
      headerClick(column, event) {
        console.log(column);
      },
  
      radioChange() {
        // this.$confirm("切换标签前请确保已保存数据, 是否继续?", "提示", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   type: "warning",
        // })
        //   .then(() => {
  
        //   })
        //   .catch(() => {});
  
        let pageLabel = this.tagData[this.radio];
        console.log(pageLabel, "pageLabel");
        this.columns = [];
        this.tableNumData = [{}];
        if (pageLabel.isUeditor === "Y") {
          this.isUeditor = pageLabel.isUeditor;
          this.isTable = "M";
        } else {
          this.isTable = pageLabel.isTable;
          this.isUeditor = "N";
        }
        if (pageLabel.id !== "") {
          let params = {
            modelId: pageLabel.modelId, //模块id
            labelId: pageLabel.id, //标签id
          };
          if (pageLabel.isTable == "Y") {
            this.getListTemplateTableData(params);
          } else {
            this.getListTemplateData(params);
          }
        }
      },
      chooseJqfl(params) {
        console.log(params, "2222");
        console.log(this.treeData, "333");
        if (params.length > 0) {
          params.forEach((item) => {
            if (item === "1123598813738675201") {
              this.treeData.forEach((item) => {
                if (item.id !== "1123598813738675201") {
                  item.disabled = true;
                }
              });
            }
          });
        } else {
          this.treeData.forEach((item) => {
            item.disabled = false;
          });
        }
      },
      //获取字典
      initData() {
        deptChildTree().then((res) => {
          this.treeData = [res.data.data];
        });
        getDictionaryTreeCode("unit_type").then((res) => {
          this.unitTypeOpt = res.data.data;
        });
        getDictionaryTreeCode("template_type").then((res) => {
          this.templateOpt = res.data.data;
        });
        getParentList(
          this.pageParent.currentPage,
          this.pageParent.pageSize,
          Object.assign({})
        ).then((res) => {
          const data = res.data.data;
          this.enumOpt = data.records;
        });
        this.loading = false;
      },
      addTableList() {
        let rowIndex = this.columns.length + 1;
        this.columns.push({
          modelId: "", //模板id
          columnName: "", //名称
          fieldType: 1, ////字段类型（1-文本框、2-下拉框，3-大文本，4-联系方式、5-证件，6-单选，7-多选 8-日期， 9-日期时间，10-数值 11-整数
          fieldStyle: "3", //字段样式
          canzhao: "", //引用参照
          enumValue: "", //枚举
          dataDefault: "", //默认值
          columnNullable: "Y", //必填项
          columnPosition: rowIndex, //显示顺序
          editState: "Y", //单据是否编辑
          singleRowStatus: "N", //是否独占一行
          showState: "Y", //单据是否显示
          unitType: "1", //单位（来源于字典接口）
          dataLength: 50, //输入长度
          columnFeild: "", //字段主键
          commentState: "N", //是否有字段说明
          columnComment: "说明内容", //字段说明
          commentExample: "示例", //填写示例
          isDatacenter: "N", //是否来源中台
          isIndex: "N", //指标名称
        });
      },
      addTableList2() {
        let rowIndex = this.columns.length + 1;
        this.tableData.push({
          // columnName0: "12",
          // columnName1: "34",
          // columnName2: "56",
        });
      },
      updateTemplateTag(id, type) {
        let params = {
          labelId: id, //标签id
          operatorType: type, //操作类型1-删除，2-开启，3-关闭
        };
        updateTemplatePageLabel(params).then((res) => {
          console.log(res);
        });
      },
      addTag() {
        this.tagData.push({
          labelPosition: "",
          labelName: "自定义标签",
          id: "",
          showState: "Y",
          columnNum: 8,
          isTable: "N",
          isUeditor: "N",
          modelId: "",
          tableNum: 1,
        });
        console.log(this.tagData, this.radio);
        this.radio = this.tagData.length - 1;
        setTimeout(() => {
          this.radioChange();
        }, 300);
      },
      changeShowState(tag) {
        if (tag.id !== "") {
          if (tag.showState === "Y") {
            this.updateTemplateTag(tag.id, 2);
          } else {
            this.updateTemplateTag(tag.id, 3);
          }
        }
      },
      removeTag(item) {
        var index = this.tagData.indexOf(item);
        if (index !== -1) {
          if (item.id !== "") {
            this.updateTemplateTag(item.id, 1);
          }
          this.tagData.splice(index, 1);
        }
        if (this.tagData.length > 0) {
          this.radio = 0;
          this.radioChange();
        }
      },
      delDataList(row) {
        var index = this.columns.indexOf(row);
        if (index !== -1) {
          this.columns.splice(index, 1);
        }
      },
      getListTemplateData(params) {
        listTemplateData(params).then((res) => {
          // this.tagData = res.data.data.templatePageLabelList;
          this.columns = res.data.data.templateTableColumnList;
          this.ueditorText = res.data.data.templateUeditor.ueditorText;
          this.columns.forEach((item) => {
            if (item.isIndex === "Y") {
              item.treeName = { indexId: item.indexId };
            }
          });
        });
      },
      getListTemplateTableData(params) {
        listTemplateTableData(params).then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            if (
              data.templatePageLabelList &&
              data.templatePageLabelList.length > 0
            ) {
              this.isTable = data.templatePageLabelList[0].isTable;
              let oldData = JSON.parse(data.templatePageLabelList[0].oldData);
              this.tableNumData = JSON.parse(JSON.stringify(oldData));
              this.tableNum = oldData.length;
              this.tagData[this.radio].tableNum = oldData.length;
            }
          }
        });
      },
      changeTableNum(num) {
        this.tableNumData = new Array(num).fill({});
      },
      saveData(formName) {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            if (this.tagData.length == 0) {
              return this.$message.error("请添加标签");
            }
            this.loading = true;
            this.modelInfo.startTime = this.modelInfo.dateTime[0];
            this.modelInfo.endTime = this.modelInfo.dateTime[1];
            let pageLabel = this.tagData[this.radio];
            pageLabel.labelPosition = this.radio + 1;
            if (pageLabel.isTable == "Y") {
              //表格保存
              let tableData = [];
              for (let i = 0; i < this.tableNumData.length; i++) {
                tableData.push({
                  th: this.$refs["childRef" + i][0].headerData,
                  td: this.$refs["childRef" + i][0].tableData,
                });
              }
              let postData = {
                pageLabel,
                modelInfo: this.modelInfo,
                tableData: tableData,
              };
              saveTemplateTableData(postData)
                .then((res) => {
                  if (res.data.code == 200) {
                    this.$message.success("保存成功");
                    this.modelInfo.paModelInfoId = res.data.data.modelId;
                    this.tagData[this.radio].modelId = res.data.data.modelId;
                    this.tagData[this.radio].id = res.data.data.labelId;
                  }
                  this.loading = false;
                })
                .catch((err) => {
                  this.loading = false;
                });
            }
            if (pageLabel.isTable == "N" && pageLabel.isUeditor == "N") {
              //表单保存
              console.log(this.ueditorText, "ueditorText");
              this.columns.forEach((item) => {
                if (item.isIndex === "Y") {
                  item.indexId = item.treeName.indexId;
                  item.columnName = item.treeName.$indexId;
                  delete item.treeName;
                }
              });
              let postData = {
                pageLabel,
                modelInfo: this.modelInfo,
                columns: this.columns,
              };
              saveTemplateData(postData)
                .then((res) => {
                  if (res.data.code == 200) {
                    this.$message.success("保存成功");
                    this.modelInfo.paModelInfoId = res.data.data.modelId;
                    this.tagData[this.radio].modelId = res.data.data.modelId;
                    this.tagData[this.radio].id = res.data.data.labelId;
                  }
                  this.loading = false;
                })
                .catch((err) => {
                  this.loading = false;
                });
            }
  
            if (pageLabel.isUeditor == "Y") {
              //说明保存
              console.log(this.ueditorText, "ueditorText");
  
              let postData = {
                pageLabel,
                modelInfo: this.modelInfo,
                ueditor: {
                  ueditorText: this.ueditorText,
                },
              };
              saveTemplateData(postData).then((res) => {
                if (res.data.code == 200) {
                  this.$message.success("保存成功");
                  this.modelInfo.paModelInfoId = res.data.data.modelId;
                  this.tagData[this.radio].modelId = res.data.data.modelId;
                  this.tagData[this.radio].id = res.data.data.labelId;
                  this.loading = false;
                }
              });
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      //查询字典
    },
  };
  </script>
  
  <style lang="scss" scoped>
::v-deep .el-form-item {
  display: flex !important;
  align-items: center;
}
::v-deep .topForm .el-select {
  width: 210px !important;
}
::v-deep .tag-list .el-select {
  width: 60px !important;
}
::v-deep .flexSelect .el-select {
  width: 70px !important;
}
::v-deep .avue-form__item-- {
  margin-bottom: 0 !important;
}
::v-deep .treeForm .el-form-item__content{
  height: 30px !important;
}
::v-deep .el-dialog__body {
  padding: 0 20px;
}
.el-cascader-panel {
  height: 300px;
}
::v-deep .el-table .el-table__cell {
  padding: 10px 0;
}
.main {
  background: #fff;
  min-height: calc(100vh - 122px);
  padding: 0 10px;
  padding-bottom: 10px;
  .add-btn {
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 0px 4px;
    /* margin-top: 10px; */
    display: inline-block;
    margin: 10px 0;
    font-size: 14px;
  }
  .tag-list {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .save-btn {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
  .table-r {
    padding: 0 10px;
  }
}
.makingClass {
  padding-bottom: 10px;
  padding-top: 10px;
}
.el-icon-delete {
  display: block;
}
#edui148,
#edui153,
#edui165,
#edui170,
#edui175 {
  display: none !important;
}
</style>
  