<template>
    <div>
        <el-popover
  placement="right"
  width="400"
  trigger="click"
  >
  <div class="thAttr">
    <el-form
        :model="modelInfo"
        class="demo-form-inline"
        size="small"
        label-width="80px"
      >
        <el-row :gutter="10">
            <el-col :span="12">
            <el-form-item label="字段样式">
              <el-select
                v-model="tipsObject.textStyle"
                placeholder="字段样式"
              >
                <el-option label="字段" value="1"></el-option>
                <el-option label="输入框" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="必输项">
              <el-select
                v-model="tipsObject.isbt"
                placeholder="必输项"
              >
                <el-option label="Y" value="Y"></el-option>
                <el-option label="N" value="N"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否有字段说明">
              <el-select
                v-model="tipsObject.isTips"
                placeholder="是否有字段说明"
              >
                <el-option label="Y" value="Y"></el-option>
                <el-option label="N" value="N"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="字段说明">
              <el-input
                v-model="tipsObject.tips"
                placeholder="字段说明"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="填写示例">
              <el-input
                v-model="tipsObject.sl"
                placeholder="填写示例"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否来源中台">
              <el-select
                v-model="tipsObject.iszt"
                placeholder="是否来源中台"
              >
                <el-option label="Y" value="Y"></el-option>
                <el-option label="N" value="N"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="字段主键">
              <el-input
                v-model="tipsObject.key"
                placeholder="字段主键"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
  </div>
  <i slot="reference" class="el-icon-s-operation"></i>
</el-popover>
    </div>
</template>

<script>
    export default {
      components: {
      },
      props:{
        tipsObject: {
            type: Object,
        }
      },
      watch:{
      },
      
   
      data() {
        return {
            tipsObject: {},
        }
      },
      methods: {
       
       
       
      }
    }
    </script>