var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "main",
    },
    [
      _c(
        "div",
        { staticClass: "search-top", staticStyle: { "padding-top": "20px" } },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "topForm",
              attrs: {
                rules: _vm.rules,
                inline: true,
                model: _vm.modelInfo,
                size: "mini",
                "label-width": "110px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 2 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "模板编号", prop: "paModelInfoCode" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { placeholder: "模板编号" },
                            model: {
                              value: _vm.modelInfo.paModelInfoCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.modelInfo, "paModelInfoCode", $$v)
                              },
                              expression: "modelInfo.paModelInfoCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "模板名称", prop: "paModelInfoName" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { placeholder: "模板名称" },
                            model: {
                              value: _vm.modelInfo.paModelInfoName,
                              callback: function ($$v) {
                                _vm.$set(_vm.modelInfo, "paModelInfoName", $$v)
                              },
                              expression: "modelInfo.paModelInfoName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "模板分类", prop: "paModelType" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "210px" },
                              attrs: { placeholder: "模板分类" },
                              model: {
                                value: _vm.modelInfo.paModelType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.modelInfo, "paModelType", $$v)
                                },
                                expression: "modelInfo.paModelType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "模板1", value: "1" },
                              }),
                              _c("el-option", {
                                attrs: { label: "模板2", value: "2" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "模板类型", prop: "paMoSelete" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "210px" },
                              attrs: { placeholder: "模板类型" },
                              on: { change: _vm.selectTeamOption },
                              model: {
                                value: _vm.modelInfo.paMoSelete,
                                callback: function ($$v) {
                                  _vm.$set(_vm.modelInfo, "paMoSelete", $$v)
                                },
                                expression: "modelInfo.paMoSelete",
                              },
                            },
                            _vm._l(_vm.templateOpt, function (item) {
                              return _c("el-option", {
                                key: item.dictKey,
                                attrs: {
                                  label: item.dictValue,
                                  value: item.dictKey,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 2 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "模板适用单位", prop: "unitList" } },
                        [
                          _c("avue-input-tree", {
                            attrs: {
                              multiple: "",
                              checkStrictly: "",
                              "default-expand-all": "",
                              placeholder: "模板适用单位",
                              dic: _vm.treeData,
                              props: { label: "deptName", value: "id" },
                            },
                            model: {
                              value: _vm.modelInfo.unitList,
                              callback: function ($$v) {
                                _vm.$set(_vm.modelInfo, "unitList", $$v)
                              },
                              expression: "modelInfo.unitList",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "模板有效期", prop: "dateTime" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "211px" },
                            attrs: {
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.modelInfo.dateTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.modelInfo, "dateTime", $$v)
                              },
                              expression: "modelInfo.dateTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务编码", prop: "busiCode" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { placeholder: "业务编码" },
                            model: {
                              value: _vm.modelInfo.busiCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.modelInfo, "busiCode", $$v)
                              },
                              expression: "modelInfo.busiCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { width: "100%" },
                  on: {
                    change: function ($event) {
                      return _vm.radioChange()
                    },
                  },
                  model: {
                    value: _vm.radio,
                    callback: function ($$v) {
                      _vm.radio = $$v
                    },
                    expression: "radio",
                  },
                },
                _vm._l(_vm.tagData, function (i, index) {
                  return _c("el-col", { key: index, attrs: { span: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "tag-list" },
                      [
                        _c(
                          "el-radio",
                          {
                            staticStyle: {
                              "margin-right": "8px",
                              "max-width": "120px",
                              overflow: "hidden",
                              "white-space": "nowrap",
                              "text-overflow": "ellipsis",
                            },
                            attrs: { label: index },
                          },
                          [_vm._v(_vm._s(i.labelName))]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "16px",
                              width: "38px",
                              display: "flex",
                            },
                          },
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "bottom",
                                  width: "200",
                                  trigger: "click",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "200px" },
                                  attrs: { clearable: "", size: "mini" },
                                  model: {
                                    value: i.labelName,
                                    callback: function ($$v) {
                                      _vm.$set(i, "labelName", $$v)
                                    },
                                    expression: "i.labelName",
                                  },
                                }),
                                _c("i", {
                                  staticClass: "el-icon-edit",
                                  staticStyle: {
                                    "margin-right": "5px",
                                    cursor: "pointer",
                                  },
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                }),
                              ],
                              1
                            ),
                            _c("i", {
                              staticClass: "el-icon-delete",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeTag(i)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("el-switch", {
                          staticStyle: { margin: "0 6px" },
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949",
                            "active-value": "Y",
                            "inactive-value": "N",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeShowState(i)
                            },
                          },
                          model: {
                            value: i.showState,
                            callback: function ($$v) {
                              _vm.$set(i, "showState", $$v)
                            },
                            expression: "i.showState",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "flexSelect",
                            staticStyle: { "font-size": "14px" },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "4px" } },
                              [_vm._v("布局")]
                            ),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "70px" },
                                attrs: { size: "mini", placeholder: "" },
                                model: {
                                  value: i.columnNum,
                                  callback: function ($$v) {
                                    _vm.$set(i, "columnNum", $$v)
                                  },
                                  expression: "i.columnNum",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "1列", value: 24 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "2列", value: 12 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "3列", value: 8 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "4列", value: 6 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { "font-size": "14px" } },
                          [
                            _c("span", { staticStyle: { margin: "0 4px" } }, [
                              _vm._v("是否表格"),
                            ]),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "60px" },
                                attrs: { size: "mini", placeholder: "" },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeTable(i.isTable)
                                  },
                                },
                                model: {
                                  value: i.isTable,
                                  callback: function ($$v) {
                                    _vm.$set(i, "isTable", $$v)
                                  },
                                  expression: "i.isTable",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "是", value: "Y" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "否", value: "N" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        i.isTable === "Y"
                          ? _c(
                              "div",
                              { staticStyle: { "font-size": "14px" } },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { margin: "0 4px" } },
                                  [_vm._v("表格个数")]
                                ),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "70px" },
                                    attrs: { size: "mini", placeholder: "" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeTableNum(i.tableNum)
                                      },
                                    },
                                    model: {
                                      value: i.tableNum,
                                      callback: function ($$v) {
                                        _vm.$set(i, "tableNum", $$v)
                                      },
                                      expression: "i.tableNum",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "1", value: 1 },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "2", value: 2 },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "3", value: 3 },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "4", value: 4 },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticStyle: { "font-size": "14px" } },
                          [
                            _c("span", { staticStyle: { margin: "0 4px" } }, [
                              _vm._v("是否是说明"),
                            ]),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "60px" },
                                attrs: { size: "mini", placeholder: "" },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeTable(i.isUeditor, 3)
                                  },
                                },
                                model: {
                                  value: i.isUeditor,
                                  callback: function ($$v) {
                                    _vm.$set(i, "isUeditor", $$v)
                                  },
                                  expression: "i.isUeditor",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "是", value: "Y" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "否", value: "N" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "save-btn" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.addTag()
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-plus" }), _vm._v("添加标签")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.saveData()
                    },
                  },
                },
                [_vm._v("保存当前标签数据")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isTable === "N"
        ? _c(
            "div",
            { staticClass: "table-list" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    data: _vm.columns,
                    "header-cell-style": {
                      background: "#fafafa",
                      color: "#000",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "50" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "显示名称", width: "140" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.isIndex === "N"
                                ? _c("el-input", {
                                    attrs: {
                                      disabled: _vm.isDisabled,
                                      clearable: "",
                                      size: "mini",
                                    },
                                    model: {
                                      value: scope.row.columnName,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "columnName", $$v)
                                      },
                                      expression: "scope.row.columnName",
                                    },
                                  })
                                : _vm._e(),
                              scope.row.isIndex === "Y"
                                ? _c("avue-form", {
                                    staticClass: "treeForm",
                                    attrs: { option: _vm.option },
                                    model: {
                                      value: scope.row.treeName,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "treeName", $$v)
                                      },
                                      expression: "scope.row.treeName",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2419402594
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "名称引用", width: "140" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDisabled,
                                    size: "mini",
                                  },
                                  model: {
                                    value: scope.row.isIndex,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "isIndex", $$v)
                                    },
                                    expression: "scope.row.isIndex",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "  ", value: "N" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "指标", value: "Y" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2523247967
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "字段类型", width: "140" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDisabled,
                                    size: "mini",
                                    placeholder: "",
                                  },
                                  model: {
                                    value: scope.row.fieldType,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "fieldType", $$v)
                                    },
                                    expression: "scope.row.fieldType",
                                  },
                                },
                                _vm._l(_vm.fieldTypeOpt, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4184553388
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "默认值", width: "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  clearable: "",
                                  size: "mini",
                                },
                                model: {
                                  value: scope.row.dataDefault,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "dataDefault", $$v)
                                  },
                                  expression: "scope.row.dataDefault",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      917184599
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "枚举值", width: "120" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    size: "mini",
                                    placeholder: "",
                                  },
                                  model: {
                                    value: scope.row.enumValue,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "enumValue", $$v)
                                    },
                                    expression: "scope.row.enumValue",
                                  },
                                },
                                _vm._l(_vm.enumOpt, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1081346841
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "单位", width: "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDisabled,
                                    size: "mini",
                                  },
                                  model: {
                                    value: scope.row.unitType,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "unitType", $$v)
                                    },
                                    expression: "scope.row.unitType",
                                  },
                                },
                                _vm._l(_vm.unitTypeOpt, function (item) {
                                  return _c("el-option", {
                                    key: item.dictKey,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item.dictKey,
                                    },
                                  })
                                }),
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2612149550
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "必填项", width: "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDisabled,
                                    size: "mini",
                                  },
                                  model: {
                                    value: scope.row.columnNullable,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "columnNullable", $$v)
                                    },
                                    expression: "scope.row.columnNullable",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "是", value: "Y" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "否", value: "N" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1851906869
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "columnPosition",
                      label: "显示顺序",
                      width: "100",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  clearable: "",
                                  size: "mini",
                                },
                                model: {
                                  value: scope.row.columnPosition,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "columnPosition", $$v)
                                  },
                                  expression: "scope.row.columnPosition",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1818468931
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address",
                      label: "单据是否编辑",
                      width: "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDisabled,
                                    size: "mini",
                                  },
                                  model: {
                                    value: scope.row.editState,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "editState", $$v)
                                    },
                                    expression: "scope.row.editState",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "是", value: "Y" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "否", value: "N" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3742833081
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address",
                      label: "是否独占一行",
                      width: "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDisabled,
                                    size: "mini",
                                  },
                                  model: {
                                    value: scope.row.singleRowStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "singleRowStatus",
                                        $$v
                                      )
                                    },
                                    expression: "scope.row.singleRowStatus",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "是", value: "Y" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "否", value: "N" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2648099574
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "dataLength",
                      label: "输入长度",
                      width: "100",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  clearable: "",
                                  size: "mini",
                                },
                                model: {
                                  value: scope.row.dataLength,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "dataLength", $$v)
                                  },
                                  expression: "scope.row.dataLength",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3726998912
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "columnFeild",
                      label: "字段主键",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address",
                      label: "是否有字段说明",
                      width: "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDisabled,
                                    size: "mini",
                                  },
                                  model: {
                                    value: scope.row.commentState,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "commentState", $$v)
                                    },
                                    expression: "scope.row.commentState",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "是", value: "Y" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "否", value: "N" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      136820854
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "columnComment",
                      label: "字段说明",
                      width: "100",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top",
                                    width: "400",
                                    trigger: "click",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "300",
                                      rows: 6,
                                      "show-word-limit": "",
                                      type: "textarea",
                                    },
                                    model: {
                                      value: scope.row.columnComment,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "columnComment",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.columnComment",
                                    },
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        size: "mini",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v("填写")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3933739890
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "commentExample",
                      label: "填写示例",
                      width: "100",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top",
                                    width: "400",
                                    trigger: "click",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "300",
                                      rows: 6,
                                      "show-word-limit": "",
                                      type: "textarea",
                                    },
                                    model: {
                                      value: scope.row.commentExample,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "commentExample",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.commentExample",
                                    },
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        size: "mini",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v("填写")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4168914156
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address",
                      label: "是否来源中台",
                      width: "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDisabled,
                                    size: "mini",
                                  },
                                  model: {
                                    value: scope.row.isDatacenter,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "isDatacenter", $$v)
                                    },
                                    expression: "scope.row.isDatacenter",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "是", value: "Y" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "否", value: "N" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3556683123
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", width: "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                { attrs: { type: "text", size: "small" } },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delDataList(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1125308517
                    ),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "center",
                    border: "1px solid #efefef",
                    margin: "10px 0",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "text",
                        disabled: this.tagData.length <= 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addTableList()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(" 添加一行数据"),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isTable === "Y"
        ? _c(
            "div",
            { staticClass: "table-r" },
            _vm._l(_vm.tableNumData, function (item, index) {
              return _c("MaKingTable", {
                key: index,
                ref: "childRef" + index,
                refInFor: true,
                staticClass: "makingClass",
                attrs: { pData: item },
              })
            }),
            1
          )
        : _vm._e(),
      _vm.isUeditor === "Y"
        ? _c(
            "div",
            [
              _c(
                "avue-ueditor",
                _vm._b(
                  {
                    model: {
                      value: _vm.ueditorText,
                      callback: function ($$v) {
                        _vm.ueditorText = $$v
                      },
                      expression: "ueditorText",
                    },
                  },
                  "avue-ueditor",
                  _vm.options,
                  false
                )
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }